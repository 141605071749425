import {
  ComponentType,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  Ref,
  createElement,
  forwardRef,
} from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';
// eslint-disable-next-line import/no-namespace
import * as ss from 'styled-system';
import {
  HeightProps,
  MarginProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  PaddingProps,
  WidthProps,
} from 'styled-system';

import { useTabContext } from 'containers/TabContext';

export type LegacyButtonProps = {
  as?: ComponentType | ElementType;
  children?: ReactNode;
  component?: ComponentType | ElementType;
  className?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  color?: string;
  bg?: string;
  fontSize?: string;
  fontWeight?: string | number;
  borderRadius?: string | number;
  border?: string;
  borderLeft?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  href?: string;
  target?: LinkProps['target'];
  rel?: LinkProps['rel'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onKeyUp?: KeyboardEventHandler<HTMLButtonElement>;
  textAlign?: string;
  title?: string;
  tabIndex?: number;
  name?: string;
  value?: string;
  disabled?: boolean;
} & MarginProps &
  PaddingProps &
  WidthProps &
  MinWidthProps &
  MaxWidthProps &
  HeightProps &
  MinHeightProps &
  MaxHeightProps;

const StyledButton = styled(
  forwardRef(
    (
      {
        as,
        component,
        type = 'button',
        m,
        mt,
        mr,
        mb,
        ml,
        mx,
        my,
        p,
        pt,
        pr,
        pb,
        pl,
        px,
        py,
        display,
        alignItems,
        justifyContent,
        width,
        height,
        minWidth,
        minHeight,
        color,
        bg,
        fontSize,
        fontWeight,
        borderRadius,
        border,
        disabled,
        ...otherProps
      }: LegacyButtonProps,
      ref: Ref<HTMLButtonElement>,
    ) => {
      const props = { ...otherProps, disabled: Boolean(disabled) };

      if (as) {
        return createElement(as, props);
      }

      if (component) {
        return createElement(component, props);
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-props-no-spreading, react/button-has-type
      return <button {...props} type={type} ref={ref} />;
    },
  ),
)<{ $transparent: boolean; $isTabbing: boolean }>`
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;

  &[disabled] {
    cursor: auto;
  }
  ${(props) =>
    !props.$isTabbing &&
    `
    outline: 0;
  `}
  ${ss.size} ${ss.display} ${ss.space} ${ss.width} ${ss.height} ${ss.minWidth} ${ss.minHeight} ${ss.fontSize} ${ss.fontWeight} ${ss.borderRadius} ${ss.color} ${ss.border}
`;

export const LegacyButton = forwardRef((props: LegacyButtonProps, ref) => {
  const isTabbing = useTabContext();

  return (
    <StyledButton
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      $isTabbing={isTabbing}
    />
  );
});
