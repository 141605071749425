import { Nullable } from 'types/Nullable';
import { del } from 'utils/http/del';
import { patchJson } from 'utils/http/patchJson';
import { putJson } from 'utils/http/putJson';

import { OrgDashboardApiOrg } from './types/OrgDashboardApiOrg';

export async function orgDashboardApiUpdateOrg(
  orgInfo: Record<string, unknown>,
): Promise<OrgDashboardApiOrg> {
  return putJson<{ org: OrgDashboardApiOrg }>(
    `/data/orgdashboard/${orgInfo.id}/organization`,
    orgInfo,
  ).then(({ org }) => org);
}

export async function orgDashboardApiPartialUpdateOrg(
  orgInfo: Pick<OrgDashboardApiOrg, 'id'> &
    Nullable<Partial<OrgDashboardApiOrg>>,
): Promise<OrgDashboardApiOrg> {
  return patchJson<{ org: OrgDashboardApiOrg }>(
    `/data/orgdashboard/${orgInfo.id}/organization`,
    orgInfo,
  ).then(({ org }) => org);
}

export function orgDashboardApiDeleteOrg(orgId: string) {
  return del(`/data/orgdashboard/${orgId}/organization`);
}
