import { postJson } from 'utils/http/postJson';

export async function approveAdminRequest(
  orgId: string,
  requestId: string,
): Promise<unknown> {
  return postJson<{ admin: unknown }>(
    `/data/orgdashboard/${orgId}/admin-requests/${requestId}`,
    {
      approved: true,
    },
  ).then(({ admin }) => admin);
}

export function rejectAdminRequest(
  orgId: string,
  requestId: string,
): Promise<unknown> {
  return postJson(`/data/orgdashboard/${orgId}/admin-requests/${requestId}`, {
    approved: false,
  });
}
